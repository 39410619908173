:host {
  display: block;
}

::placeholder {
  // padding-top: 10px;
}

//pages
.main-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-mobile {
    .first-section {
      .first-content {
        .first-text {
          display: flex;
          //   flex-direction: column;
          align-items: center;
          position: relative;
          z-index: 1;

          & > * {
            margin-bottom: 1vh;
          }

          &-title {
            text-align: center;
          }

          &-paragraphs {
            display: flex;
            flex-direction: column;

            & > div:first-child {
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }

  .whatsapp-button {
    position: fixed;
    top: 88%;
    left: 50px;
    z-index: 2;

    @media screen and(max-width: 1200px) {
      left: 20px;
      top: 90%;
    }
  }

  section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .underline {
        @media screen and(max-width: 750px) {
          width: 250px;
          padding-top: 20px;
          margin-bottom: 40px;
        }
      }
    }
  }

  .first-section {
    min-height: var(--main-first-section-min-height);
    box-sizing: border-box;
    position: relative;

    .section-padding {
      display: flex;

      .first-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-left: 20px;

        & > :first-child {
          margin-inline-end: 5vw;
        }

        .first-text {
          display: flex;
          flex-direction: column;
          flex: 1;
          position: relative;
          z-index: 1;

          & > * {
            margin-bottom: 15px;
          }

          @media screen and(max-width: 1200px) {
            width: var(--main-first-section-text-width-mw1200);
            align-self: var(--main-first-section-text-align-self-mw1200);
          }

          @media screen and(max-width: 530px) {
            width: var(--main-first-section-text-width-mw520);
            align-self: var(--main-first-section-text-align-self-mw520);
            text-align: var(--main-first-section-text-align-mw520);
          }

          &-paragraphs {
            div {
              padding-bottom: 15px;
            }
          }
        }

        .heart-bubbles {
          position: relative;
          flex: 1;

          .heart-bubble-1 {
            img {
              position: absolute;
              left: 50px;
              top: -300px;
              width: 120px;

              @media screen and(max-width: 1200px) {
                top: -90px;
              }

              @media screen and(max-width: 900px) {
                width: 80px;
                top: -60px;
              }

              @media screen and(max-width: 495px) {
                top: -60px;
                left: 30px;
              }
            }
          }

          .heart-bubble-2 {
            img {
              position: absolute;
              right: 50px;
              bottom: -350px;
              width: 180px;

              @media screen and(max-width: 1200px) {
                right: -300px;
                top: 400px;
              }

              @media screen and(max-width: 900px) {
                width: 120px;
                right: -220px;
                bottom: -450px;
              }

              @media screen and(max-width: 585px) {
                width: 100px;
                right: -130px;
                bottom: -550px;
              }

              @media screen and(max-width: 585px) {
                right: -100px;
                top: 500px;
              }
              @media screen and(max-width: 400px) {
                top: 600px;
                right: -130px;
              }
            }
          }

          .heart-bubble-3 {
            img {
              position: absolute;
              right: 400px;
              bottom: -300px;
              width: 250px;

              @media screen and(max-width: 1200px) {
                left: 200px;
              }

              @media screen and(max-width: 900px) {
                width: 150px;
              }

              @media screen and(max-width: 800px) {
                top: 500px;
                right: 50px;
                left: auto;
              }

              @media screen and(max-width: 495px) {
                top: 500px;
                right: 50px;
                left: auto;
              }
              @media screen and(max-width: 400px) {
                top: 550px;
                right: 0px;
              }
            }
          }
        }

        .first-display-container {
          position: relative;
          flex: 1;
          padding-right: 20px;

          @media screen and(max-width: 1200px) {
            top: 264px;
            right: -260px;
          }

          @media screen and(max-width: 705px) {
            top: 290px;
          }

          @media screen and(max-width: 661px) {
            top: 342px;
          }

          @media screen and(max-width: 560px) {
            right: 0;
          }

          @media screen and(max-width: 520px) {
            top: 374px;
          }

          @media screen and(max-width: 511px) {
            top: 374px;
          }

          .comments-cards {
            position: relative;
            .card-0,
            .card-1,
            .card-2,
            .card-3,
            .card-4 {
              position: absolute;
              width: 396px;
              height: 164px;
              border-radius: 15px;
              box-shadow: var(--comments-first-section-card-shadow);
              // perspective: 1000px;
              // transition: transform 0.8s;
              // transform-style: preserve-3d;

              @media screen and(max-width: 1375px) {
                width: 338px;
                height: 140px;
              }

              @media screen and(max-width: 661px) {
                width: 274px;
                height: 113px;
              }

              @media screen and(max-width: 520px) {
                width: 206px;
                height: 85px;
              }

              @media screen and(max-width: 420px) {
                width: 194px;
                height: 81px;
              }
            }

            // .card-flip {
            //   animation-delay: 0.7s;
            //   transform: rotateY(90deg);
            // }

            // .card-flip-back {
            //   animation-delay: 0.4s;
            //   // transform: rotateY(-90deg);
            //   transform: rotateY(90deg);
            // }

            @keyframes fadeIn {
              0% {
                opacity: 0;
              }

              25% {
                opacity: 0.25;
              }

              50% {
                opacity: 0.5;
              }

              75% {
                opacity: 0.75;
              }

              100% {
                opacity: 1;
              }
            }

            @keyframes fadeOut {
              100% {
                opacity: 0;
              }

              75% {
                opacity: 0.75;
              }

              50% {
                opacity: 0.5;
              }

              25% {
                opacity: 0.25;
              }

              0% {
                opacity: 1;
              }
            }

            .fade-in {
              // animation-delay: 2s;
              animation-timing-function: ease;
              animation-name: fadeIn;
              animation-duration: 0.7s;
            }

            .fade-out {
              animation-timing-function: ease;
              animation-name: fadeOut;
              animation-duration: 0.7s;
            }

            .card-0 {
              top: -360px;
              right: -15px;
              width: 496px;
              height: 207px;
              z-index: 5;

              &.fade-in {
                z-index: 5 !important;
              }

              @media screen and(max-width: 1375px) {
                width: 423px;
                height: 177px;
                top: -321px;
              }

              @media screen and(max-width: 661px) {
                width: 343px;
                height: 144px;
                top: -321px;
                right: 35px;
              }

              @media screen and(max-width: 560px) {
                right: -225px;
              }

              @media screen and(max-width: 520px) {
                width: 258px;
                height: 108px;
                top: -344px;
                right: -165px;
              }

              @media screen and(max-width: 420px) {
                width: 244px;
                height: 102px;
                top: -344px;
                right: -156px;
              }
            }

            .card-1 {
              top: -170px;
              right: 220px;
              z-index: 4;

              &.fade-in {
                z-index: 4 !important;
              }

              @media screen and(max-width: 1375px) {
                top: -160px;
                right: 192px;
              }

              @media screen and(max-width: 661px) {
                top: -191px;
              }

              @media screen and(max-width: 560px) {
                right: -68px;
              }

              @media screen and(max-width: 520px) {
                top: -245px;
                right: -39px;
              }

              @media screen and(max-width: 420px) {
                top: -251px;
              }

              @media screen and(max-width: 380px) {
                right: -46px;
              }
            }

            .card-2 {
              top: -35px;
              right: -4px;
              z-index: 3;

              &.fade-in {
                z-index: 3 !important;
              }

              @media screen and(max-width: 1375px) {
                top: -48px;
              }

              @media screen and(max-width: 661px) {
                top: -100px;
                right: 55px;
              }

              @media screen and(max-width: 560px) {
                right: -205px;
              }

              @media screen and(max-width: 520px) {
                top: -177px;
                right: -150px;
              }

              @media screen and(max-width: 420px) {
                top: -186px;
                right: -138px;
              }

              @media screen and(max-width: 380px) {
                right: -145px;
              }
            }

            .card-3 {
              top: 77px;
              right: 248px;
              z-index: 2;

              &.fade-in {
                z-index: 2 !important;
              }

              @media screen and(max-width: 1375px) {
                top: 47px;
                right: 192px;
              }

              @media screen and(max-width: 661px) {
                top: -19px;
                right: 215px;
              }

              @media screen and(max-width: 560px) {
                right: -45px;
              }

              @media screen and(max-width: 520px) {
                top: -119px;
                right: -30px;
              }

              @media screen and(max-width: 420px) {
                top: -130px;
                right: -25px;
              }

              @media screen and(max-width: 380px) {
                right: -32px;
              }
            }

            .card-4 {
              top: 202px;
              right: -4px;
              z-index: 3;

              &.fade-in {
                z-index: 3 !important;
              }

              @media screen and(max-width: 1375px) {
                top: 168px;
              }

              @media screen and(max-width: 661px) {
                top: 74px;
                right: 55px;
              }

              @media screen and(max-width: 560px) {
                right: -205px;
              }

              @media screen and(max-width: 520px) {
                top: -47px;
                right: -150px;
              }

              @media screen and(max-width: 420px) {
                top: -64px;
                right: -138px;
              }

              @media screen and(max-width: 380px) {
                right: -145px;
              }
            }
          }

          .blue-tick-bubbles {
            img {
              z-index: 20;
            }
            .blue-tick-bubble-1 {
              img {
                position: absolute;
                left: 24px;
                top: -300px;
                width: 186px;

                @media screen and(max-width: 1200px) {
                  top: -312px;
                  left: -575px;
                }

                @media screen and(max-width: 900px) {
                  width: 131px;
                  top: -278px;
                }

                @media screen and(max-width: 661px) {
                  width: 87px;
                  top: -225px;
                  left: -481px;
                }

                @media screen and(max-width: 560px) {
                  left: -221px;
                }

                @media screen and(max-width: 520px) {
                  width: 77px;
                  top: -306px;
                  left: -168px;
                }

                @media screen and(max-width: 420px) {
                  width: 90px;
                  top: -317px;
                }
              }
            }

            .blue-tick-bubble-2 {
              img {
                position: absolute;
                right: -47px;
                bottom: 90px;
                width: 118px;

                @media screen and(max-width: 1670px) {
                  right: 10px;
                  bottom: 90px;
                }

                @media screen and(max-width: 1547px) {
                  right: 40px;
                  bottom: 65px;
                }

                @media screen and(max-width: 1200px) {
                  right: -25px;
                  top: -190px;
                }

                @media screen and(max-width: 705px) {
                  right: 2px;
                }

                @media screen and(max-width: 661px) {
                  width: 78px;
                  right: 75px;
                  top: -206px;
                }

                @media screen and(max-width: 560px) {
                  right: -185px;
                }

                @media screen and(max-width: 520px) {
                  width: 68px;
                  top: -267px;
                  right: -142px;
                }

                @media screen and(max-width: 420px) {
                  width: 58px;
                  top: -267px;
                  right: -113px;
                }
              }
            }

            .blue-tick-bubble-3 {
              img {
                position: absolute;
                right: 384px;
                bottom: -440px;
                width: 255px;

                @media screen and(max-width: 1375px) {
                  right: 322px;
                  bottom: -400px;
                }

                @media screen and(max-width: 1200px) {
                  left: -532px;
                  bottom: 207px;
                }

                @media screen and(max-width: 900px) {
                  width: 200px;
                  bottom: 264px;
                }

                @media screen and(max-width: 705px) {
                  bottom: 248px;
                }

                @media screen and(max-width: 661px) {
                  width: 133px;
                  bottom: 340px;
                  left: -478px;
                }

                @media screen and(max-width: 560px) {
                  left: -218px;
                }

                @media screen and(max-width: 520px) {
                  width: 123px;
                  bottom: 350px;
                  left: -173px;
                }

                @media screen and(max-width: 420px) {
                  width: 125px;
                  bottom: 353px;
                  left: -167px;
                }

                @media screen and(max-width: 362px) {
                  bottom: 353px;
                }
              }
            }
          }
        }

        .first-animation {
          flex: 1;
        }
      }
    }

    @media screen and(max-width: 1200px) {
      .section-padding {
        margin-top: var(--main-first-section-margin-top);

        .first-content {
          flex-direction: column-reverse;

          .first-animation {
            justify-content: center;
            display: flex;
            width: 100%;
          }
        }
      }
    }
  }

  .clouds {
    width: 100%;
    position: absolute;
    bottom: 0;
    transform: rotate(180deg);

    svg-icon {
      transform: translateX(-50%) rotateY(180deg);
    }
  }

  .pricing-section {
    background-color: var(--main-pricing-section-bg-color);
    padding-top: var(--main-pricing-section-padding-top);

    .heading {
      .section-title {
        margin-bottom: var(--main-pricing-section-title-bottom-margin);
      }

      .underline {
        display: var(--main-pricing-section-underline-display);
      }
    }

    .pricing-content {
      .cards {
        display: grid;
        grid-gap: 60px;
        grid-template-columns: repeat(auto-fit, 360px);
        justify-content: center;

        @media screen and(max-width: 480px) {
          grid-template-columns: repeat(auto-fit, 285px);
        }
      }

      .pricing-card {
        display: flex;
        justify-content: center;

        mat-card {
          max-width: 420px;

          box-sizing: border-box;
          padding: 0;

          @media screen and(max-width: 1040px) {
            //   max-width: 330px;
          }

          @media screen and(max-width: 480px) {
            //    max-width: 285px;
          }

          .plan-type {
            display: flex;
            height: 50px;
            padding: 20px 20px 0;

            .weekly {
              height: 100%;
              flex: 1;
            }

            .monthly {
              height: 100%;
              flex: 1;
            }
          }

          mat-card-content {
            padding: 0 40px 10px 40px;
            position: relative;
            overflow: hidden;

            & > div {
              margin-bottom: 0;
              min-width: 320px;
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            .pricing-titles {
              div:first-child {
                margin-bottom: 25px;
              }
            }

            .divider {
              width: 280px;
              margin: 15px 0;
            }

            .benefits-list {
              .list-item {
                padding-left: 15px;
              }
            }

            .price {
              margin-bottom: 20px;
              padding: 5px 0;
            }

            @media screen and(max-width: 480px) {
              & > div {
                min-width: 270px;
              }
            }
            @media screen and(max-width: 400px) {
              & > div {
                min-width: 230px;
              }
            }
          }
        }
      }
    }
  }

  .how-section {
    .section-padding {
      max-width: var(--main-how-section-max-width);
    }

    .heading {
      align-items: var(--main-how-section-heading-align-items);

      .section-sub-title {
        text-align: center;
        display: var(--main-how-section-sub-title-display);
      }

      .underline {
        width: var(--main-section-title-underline-width);
        max-width: var(--main-how-section-max-width);
        margin-bottom: var(--main-how-section-heading-underline-margin-bottom);
      }
    }

    .how-content {
      display: flex;
      justify-content: center;
      padding-top: var(--main-how-section-content-padding-top);

      & > :first-child {
        margin-inline-end: var(--main-how-section-column-gap);
      }

      @media screen and(max-width: 950px) {
        flex-direction: var(--main-how-section-content-flex-direction-mw-950);
        align-items: var(--main-how-section-content-align-items-mw-950);

        & > :first-child {
          margin-inline-end: 0;
          margin-bottom: var(--main-how-section-content-row-gap-mw-950);
        }
      }

      .how-section-image {
        position: relative;

        img {
          width: 100%;

          @media screen and(min-width: 950px) {
            max-width: 420px;
          }
        }

        .heart-bubble-1,
        .blue-tick-bubble-1 {
          img {
            position: absolute;
            right: 25px;
            top: 0px;
            width: 120px;
          }
        }

        .heart-bubble-2,
        .blue-tick-bubble-2 {
          img {
            position: absolute;
            right: calc(100% - 193px);
            bottom: -80px;
            width: 180px;

            @media screen and(max-width: 500px) {
              right: calc(100% - 130px);
              bottom: -50px;
              width: 140px;
            }
          }
        }
      }

      .steps {
        display: flex;
        flex-direction: var(--main-how-section-steps-flex-direction);
        justify-content: center;
        z-index: 1;

        & > :not(:last-child) {
          margin-bottom: var(--main-how-section-steps-row-gap);
          margin-inline-end: var(--main-how-section-steps-column-gap);
        }

        @media screen and(max-width: 1200px) {
          flex-direction: column;
          & > :not(:last-child) {
            margin-bottom: var(--main-how-section-steps-row-gap-mw1200);
            margin-inline-end: 0;
          }
        }

        .how-step {
          display: flex;
          flex-direction: column;
          align-items: var(--main-how-section-steps-align-items);
          box-sizing: border-box;

          & > :first-child {
            margin-bottom: 15px;
          }

          padding: 20px 0;

          .step-number {
            display: flex;
            justify-content: center;
            align-items: center;
            width: var(--main-how-section-step-number-width);
            height: var(--main-how-section-step-number-height);
            padding: 0 0 2px 1px;
          }

          .step-guide {
            text-align: var(--main-how-section-step-guide-ta);
            line-height: 35px;
            max-width: 460px;
          }

          .step-lottie {
            width: 360px;
            height: 410px;

            @media screen and(max-width: 1200px) {
              width: 450px;
            }

            @media screen and(max-width: 500px) {
              width: 300px;
            }
          }
        }
      }
    }
  }

  .reviews-section {
    .heading {
      .section-title {
        margin-bottom: var(--main-reviews-section-title-bottom-margin);
      }

      .underline {
        display: var(--main-reviews-section-underline-display);
      }
    }

    .reviews {
      box-sizing: border-box;
      display: flex;

      & > :not(:last-child) {
        margin-inline-end: 30px;
      }

      justify-content: center;

      @media screen and(max-width: 1400px) {
        flex-direction: column;
        align-items: center;
        & > :not(:last-child) {
          margin-inline-end: 0px;
          margin-bottom: 50px;
        }
      }

      .review-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        max-width: 220px;

        & > :not(:last-child) {
          margin-bottom: 10px;
        }

        iframe,
        video {
          width: 200px;
        }

        .review-stars {
          display: flex;

          & > :not(:last-child) {
            margin-inline-end: 15px;
          }

          div {
            display: grid;
            place-content: center;
            height: 28px;
            width: 28px;
          }
        }
      }
    }
  }

  .buy-likes-section {
    .section-title {
      @media screen and(max-width: 750px) {
        max-width: 380px;
      }
    }

    .buy-likes-content {
      display: flex;
      justify-content: center;
      padding: 10px 0;

      & > :first-child {
        margin-inline-end: 4vw;
      }

      @media screen and(max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        // gap: 50px;
      }

      .buy-likes-widget {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 360px;

        @media screen and(max-width: 450px) {
          width: 300px;
        }

        .heart {
          padding: 20px 0 10px 0;
        }

        .widget-title {
          padding-bottom: 55px;
        }

        .widget-instruction {
          position: relative;
          top: 19px;
        }

        .sale-container {
          min-height: 75px;
          padding-top: 20px;
          margin-top: 10px;

          .sale-outer {
            width: 65px;
            height: 65px;
            padding: 3px 0 0 3px;

            .sale-inner {
              width: 60px;
              height: 60px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .widget-sale-button {
          margin-top: 25px;
          margin-bottom: 25px;
        }
      }

      .buy-likes-text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: auto;
        // gap: 35px;
        margin-top: 50px;

        .buy-likes-details {
          display: flex;
          flex-direction: column;
          // gap: 25px;

          .buy-likes-title {
            margin-bottom: 25px;
            @media screen and(max-width: 1000px) {
              align-self: center;
            }
          }

          .buy-likes-paragraph {
            margin-bottom: 25px;
            @media screen and(max-width: 590px) {
              max-width: 400px;
            }
          }
        }
      }
    }
  }

  .why-section {
    .heading {
      .section-title {
        margin-bottom: var(--main-why-section-title-bottom-margin);
      }
    }

    .why-content {
      display: var(--main-why-section-content-display);
      grid-template-columns: var(
        --main-why-section-content-grid-template-columns
      );
      place-content: var(--main-why-section-content-place-content);

      & > :not(:last-child) {
        margin-inline-end: var(--main-why-section-content-column-gap);
        margin-bottom: var(--main-why-section-content-row-gap);
      }

      @media screen and(min-width: 1400px) {
        grid-template-columns: var(
          --main-why-section-content-grid-template-columns-minw1400
        );
      }

      flex-wrap: var(--main-why-section-content-flex-wrap);
      justify-content: var(--main-why-section-content-justify-content);

      @media screen and(max-width: 1050px) {
        flex-direction: var(--main-why-section-content-flex-direction-mw1050);
        align-items: var(--main-why-section-content-align-items-mw1050);
        & > :not(:last-child) {
          margin-inline-end: 0;
        }
      }

      .reason {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: var(--main-why-section-reason-justify-content);
        min-height: 200px;
        width: 280px;

        & > :not(:last-child) {
          margin-bottom: var(--main-why-section-reason-row-gap);
        }

        @media screen and(min-width: 1400px) {
          width: var(--main-why-section-reason-width-minw1400);
        }

        &-heading {
          width: var(--main-why-section-reason-heading-width);
        }
      }
    }
  }

  .buy-followers-section {
    .overlay {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .section-title {
      margin-bottom: 10px;
      @media screen and(max-width: 750px) {
        max-width: 380px;
      }
    }

    .section-sub-title {
      @media screen and(max-width: 750px) {
        max-width: 380px;
      }
    }

    .buy-followers-content {
      display: flex;
      justify-content: center;
      column-gap: 4vw;
      padding: 20px 0;

      @media screen and(max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 50px;
      }

      .buy-followers-widget {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 360px;
        // height: 390px;

        @media screen and(max-width: 450px) {
          width: 300px;
        }

        .heart {
          padding: 20px 0 10px 0;
        }

        .widget-title {
          padding-bottom: 55px;
        }

        .widget-instruction {
          position: relative;
          top: 19px;
        }

        .sale-container {
          min-height: 75px;
          padding-top: 20px;
          margin: 10px 0;

          .sale-outer {
            width: 65px;
            height: 65px;
            padding: 3px 0 0 3px;

            .sale-inner {
              width: 60px;
              height: 60px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .widget-sale-button {
          margin: 25px 0;
        }
      }

      .buy-followers-text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: auto;
        // gap: 35px;
        margin-top: 35px;

        .buy-followers-details {
          display: flex;
          flex-direction: column;
          // gap: 25px;

          .buy-followers-title {
            margin-bottom: 25px;
            @media screen and(max-width: 1000px) {
              align-self: center;
            }
          }

          .buy-followers-paragraph {
            margin-bottom: 25px;
            @media screen and(max-width: 590px) {
              max-width: 400px;
            }
          }
        }
      }
    }
  }

  .faq-section {
    .heading {
      .section-title {
        margin-bottom: var(--main-faq-section-title-bottom-margin);
      }
    }

    .faq-content {
      display: flex;
      justify-content: center;

      .questions-answers {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: var(--main-faq-section-questions-row-gap);
        min-height: 500px;

        .question-container {
          width: 55vw;
          max-width: 800px;

          @media screen and(max-width: 900px) {
            width: 70vw;
          }

          @media screen and(max-width: 400px) {
            width: 250px;
          }

          .question {
            min-height: 70px;
            height: auto;
            padding-top: 10px;
            padding-bottom: 10px;
          }

          .answer {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-top: 16px;
            height: fit-content;
          }
        }
      }
    }
  }
}

.comments {
  .pricing-section {
    .pricing-content {
      .cards {
        // display: flex;
        display: grid;
        grid-row-gap: 60px;
        grid-column-gap: 0px;
        grid-template-columns: repeat(3, 360px);
        justify-content: center;

        @media screen and(max-width: 480px) {
          grid-template-columns: repeat(auto-fit, 285px);
        }

        &.mobile {
          display: flex;
        }
      }

      .pricing-card {
        display: flex;
        justify-content: center;

        mat-card {
          max-width: 300px;
          box-sizing: border-box;
          padding: 0;
          border-top: 15px solid transparent;

          @media screen and(max-width: 1040px) {
            //   max-width: 330px;
          }

          @media screen and(max-width: 480px) {
            //    max-width: 285px;
          }

          .plan-type {
            display: flex;
            justify-content: center;
            font-size: var(--comments-pricing-card-medium-font-size);
            font-weight: var(--comments-pricing-card-title-font-weight);
            height: initial;
            padding: 15px 20px 0;
          }

          mat-card-content {
            padding: 0 20px 10px 20px;
            // position: relative;
            // overflow: hidden;
            .mat-list-item-content {
              justify-content: center;
              padding: 0;
            }

            & > div {
              margin-bottom: 0;
              min-width: 260px;
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            .pricing-titles {
              padding-top: 24px;
              div:first-child {
                margin-bottom: 10px;
              }
            }

            .divider {
              width: 280px;
              margin: 31px 0 15px;
            }

            .benefits-list {
              .list-item {
                padding-left: 0px;
              }
            }

            .price {
              margin-bottom: 16px;
              padding: 5px 0;
            }

            @media screen and(max-width: 480px) {
              & > div {
                min-width: 270px;
              }
            }
            @media screen and(max-width: 400px) {
              & > div {
                min-width: 230px;
              }
            }
          }
        }
      }
    }
  }
}

.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  &-container {
    max-width: 1520px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 35px 30px;

    .contact-details {
      column-gap: 10vw;

      @media screen and(max-width: 830px) {
        flex-direction: column;
      }

      .underline {
        width: 40px;
        margin-bottom: 35px;
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding: 30px 0;
      position: relative;

      .form-title {
        margin-bottom: 20px;
      }

      .form-inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;

        input {
          width: 70%;
          height: 40px;
          min-width: 200px;
          max-width: 420px;
        }

        textarea.message {
          height: 80px;
          width: 70%;
          min-width: 200px;
          max-width: 420px;
        }
      }

      .form-button {
        margin-top: 20px;
        width: 100px;
      }
    }

    .payment-status {
      .success-notice {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .success {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 50px;
      }
    }
  }
}

.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  overflow: hidden;
  padding-top: 90px;

  &-container {
    width: var(--contact-us-container-width);
    max-width: 800px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding: 35px 30px;

    .heading {
      display: flex;
      flex-direction: column;
      align-items: var(--contact-us-contact-heading-align-items);
    }

    .contact-details {
      display: var(--contact-us-contact-details-display);
      column-gap: 10vw;

      @media screen and(max-width: 830px) {
        flex-direction: column;
      }

      .underline {
        width: 40px;
        margin-bottom: 35px;
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0;

      .form-title {
        margin-bottom: 30px;
      }

      .form-inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;

        input {
          width: 70%;
          height: 40px;
          min-width: 200px;
          max-width: 420px;

          @media screen and(max-width: 410px) {
            min-width: 150px;
          }
        }

        textarea.message {
          height: 80px;
          width: 70%;
          min-width: 200px;
          max-width: 420px;

          @media screen and(max-width: 410px) {
            min-width: 150px;
          }
        }
      }

      .form-button {
        margin-top: 20px;
        width: var(--contact-us-form-button-width);
        height: 50px;
        max-width: 420px;
      }
    }
  }
}

.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;

  section {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .section-padding {
    padding: 50px 10vw;
  }

  .first-section {
    .first-content {
      .first-text {
        display: flex;
        justify-content: center;
        padding: 0 50px 50px;

        @media screen and(max-width: 950px) {
          flex-direction: column;
          row-gap: 50px;
        }

        .first-title {
          flex: 1;
          padding-top: 10px;
        }

        .first-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          row-gap: 15px;

          .underline {
            width: 100px;

            &:nth-child(1) {
              margin-bottom: 20px;
              padding-top: 0;
            }

            &:nth-child(2) {
              @media screen and(max-width: 950px) {
                display: none;
              }
            }
          }
        }
      }

      .first-img {
        width: 100%;
        @media screen and(max-width: 950px) {
          padding-top: 25px;
        }

        @media screen and(min-width: 950px) {
          width: 460px;
          position: relative;
          left: 42%;
          top: -60px;
          padding: 10px 0 0px 38px;
        }

        img {
          width: 100%;

          @media screen and(min-width: 950px) {
            width: 490px;
            position: relative;
            bottom: 30px;
            image-rendering: auto;
          }
        }
      }
    }
  }

  .how-why-sections {
    width: 100%;

    .how-section {
      .how-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .how-title {
          margin-bottom: 25px;
        }
      }
    }

    .why-us-section {
      .why-us-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .why-us-title {
          // font-size: 32px;
          margin-bottom: 25px;
        }

        .why-us-paragraph {
          max-width: 525px;
        }
      }
    }
  }

  .work-with-us-section {
    .work-with-us-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .underline {
        width: 160px;
        padding-top: 25px;
      }

      .reasons {
        display: grid;
        grid-template-columns: repeat(auto-fit, 230px);
        place-content: center;
        grid-gap: 20px;
        width: 100%;
        z-index: 0;

        @media screen and(min-width: 900px) and(max-width: 1400px) {
          grid-template-columns: repeat(3, 230px);
        }

        @media screen and(min-width: 1400px) {
          grid-template-columns: repeat(auto-fit, 170px);
        }

        .reason {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 40px 10px;
          row-gap: 20px;
          max-width: 230px;
          box-sizing: border-box;

          @media screen and(min-width: 1400px) {
            max-width: 170px;
          }
        }
      }
    }
  }
}

.privacy-policy {
  box-sizing: border-box;
  padding: 20px 10vw;
  padding-top: 90px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .page-title {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .content {
    max-width: 1000px;
    width: 100%;

    .list {
      li {
        padding-bottom: 4px;
      }
    }
  }
}

.terms-of-use {
  box-sizing: border-box;
  padding: 20px 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;

  .page-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .content {
    max-width: 1000px;
    width: 100%;

    .list {
      li {
        padding-bottom: 4px;
      }
    }
  }
}

//modals
.buy-followers-modal {
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 0px 10px;

    &-buttons {
      width: 100%;
      display: flex;

      .back-button-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }

      .close-button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    .modal-title {
      margin: 0;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .dialog-content {
      width: 100%;

      .users-content {
        width: 100%;
      }
    }

    .progress-bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px;
      width: 50%;
      min-height: 200px;
    }

    .user-summary {
      &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        padding: 40px 0 0;

        .user-summary-details {
          display: flex;
          width: 100%;
          justify-content: space-around;
          align-items: center;
          column-gap: 20px;

          @media screen and(max-width: 580px) {
            flex-direction: column;
            row-gap: 20px;
          }

          .details-section {
            .user-name {
              max-width: 150px;

              @media screen and(max-width: 580px) {
                max-width: none;
              }
            }

            .full-name {
              max-width: 150px;

              @media screen and(max-width: 580px) {
                max-width: none;
              }
            }

            img {
              min-height: 150px;
              width: 150px;
              height: 150px;

              @media screen and(max-width: 450px) {
                min-height: 125px;
                width: 125px;
                height: 125px;
              }
            }
          }
        }

        .payment-button {
          fa-icon {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.instagram-likes-modal {
  .heading {
    padding: 10px 10px 30px 10px;

    &-buttons {
      width: 100%;
      display: flex;

      .back-button-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }

      .close-button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    .mdoal-title {
      margin-bottom: 5px;
    }
  }

  .payment-button {
    fa-icon {
      margin-right: 5px;
    }
  }

  @media screen and(max-width: 450px) {
    .heading {
      padding-bottom: 10px;
    }
  }
}

.signup-modal {
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 20px 10px;

    &-buttons {
      width: 100%;
      display: flex;

      .back-button-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }

      .close-button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    .modal-title {
      margin-bottom: 5px;
    }

    .modal-sub-title {
      @media screen and(max-width: 500px) {
        max-width: 200px;
      }
    }

    .profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      min-height: 110px;
      margin-top: 5px;

      img {
        width: 80px;
        height: 80px;
      }
    }
  }

  .signup-users {
    overflow: hidden;

    &-content {
      width: 100%;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;

    .form-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 150px;
      max-width: 500px;
      width: 80%;
      row-gap: 10px;

      .group-container {
        width: 100%;
      }

      .input {
        width: 100%;

        .signup-input {
          width: 100%;
        }
      }

      .signup-button {
        width: 135px;
        height: 45px;
        margin-top: 10px;
      }
    }
  }

  .signup-spinner-container {
    padding: 50px 0;
  }
}

//components
.footer {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  min-height: 365px;
  padding: 6vh 7vw;

  @media screen and(max-width: 900px) {
    padding: 2vh 10vw;
  }

  .content {
    justify-content: center;
    column-gap: 7vw;

    .footer-half {
      display: flex;
      column-gap: 7vw;

      @media screen and(max-width: 900px) {
        flex-direction: column;
      }

      .footer-column {
        min-height: 130px;
        row-gap: 5px;
        display: flex;
        flex-direction: column;

        .underline {
          width: var(--footer-underline-width);

          @media screen and(max-width: 900px) {
            margin-bottom: 20px;
          }

          &.first {
            visibility: var(--footer-underline-first-visibility);
          }
        }

        .app-footer-icon {
          a {
            img {
              padding-top: var(--footer-app-padding-top);

              @media screen and(min-width: 900px) {
                max-width: var(--footer-app-icon-minw900-width);
              }

              @media screen and(max-width: 900px) {
                max-width: var(--footer-app-icon-width);
              }

              @media screen and(max-width: 355px) {
                max-width: var(--footer-app-icon-mw355-width);
              }
            }
          }
        }
      }
    }
  }
}

.header {
  .app-logo {
    width: 230px;

    @media screen and(max-width: 450px) {
      width: 200px;
    }
  }

  .toolbar {
    position: relative;
    z-index: 2;

    .services-available {
      height: 55px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      @media screen and(max-width: 355px) {
        column-gap: 3px;
      }

      .services-icon {
        display: flex;
        padding-right: 5px;

        svg-icon {
          display: flex;
          align-items: center;

          svg {
            height: 30px;

            @media screen and(max-width: 500px) {
              height: 25px;
            }
          }
        }
      }

      .services-text-and-date {
        display: flex;
        font-weight: 100;
        @media screen and(max-width: 380px) {
          flex-direction: column;
          align-items: center;
        }

        .services-text {
          @media screen and(max-width: 380px) {
            line-height: 18px;
          }
          @media screen and(max-width: 400px) {
            font-size: 12px;
          }
        }

        .services-date {
          padding-left: 20px;
          @media screen and(max-width: 380px) {
            line-height: 18px;
          }
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 39px;
        width: 39px;
        height: 39px;
        border-radius: 50%;
        font-size: 20px;

        @media screen and(max-width: 500px) {
          min-width: 30px;
          width: 30px;
          height: 30px;
        }
      }
    }

    .toolbar-content {
      width: 100%;
      display: flex;
      justify-content: var(--header-justify-content);
      column-gap: var(--header-column-gap);
      align-items: center;
      padding: var(--header-padding);

      @media screen and(max-width: 961px) {
        width: fit-content;
        column-gap: 0;
      }

      a {
        @media screen and(max-width: 1140px) {
          padding: 0 10px;
        }
      }
    }
  }

  .sideNavTabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    a {
      height: 55px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.instagram-content {
  padding: 20px 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;

    .not-found-errors {
      .not-found-message {
        max-width: 340px;
      }
    }

    .search-container {
      // margin-bottom: 20px;
    }

    .progress-bar {
      display: flex;
      flex-direction: column;
      margin: 20px;
      width: 50%;
    }

    .search-results {
      box-sizing: border-box;
      padding: 30px 0;
      //  gap: 20px 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      overflow: auto;
      max-height: 42vh;

      app-instagram-user {
        margin: 10px;
      }
    }

    .posts {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .user-posts {
        box-sizing: border-box;
        padding: 30px 0;
        //   gap: 20px 10px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow: auto;
        max-height: 42vh;

        .post {
          position: relative;
          margin: 10px;

          .selected-post {
            display: flex;
            height: 100px;
            width: 100px;
            position: absolute;
            top: 0;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.instagram-user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 135px;
  width: 120px;

  .profile-pic {
    img {
      width: 80px;
      height: 80px;
    }
  }

  .user-name {
    max-width: 100px;
  }
}

.instagram-user-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  position: relative;

  .post-image {
    img {
      object-fit: cover;
      width: 100px;
      height: 100px;
    }
  }

  .post-likes {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 3px;
  }
}

.pricing-card {
  mat-card {
    mat-card-content {
      padding: 0 50px 10px 50px;

      .pricing-titles {
        padding-top: 35px;
      }
    }
  }
}
