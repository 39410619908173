:root{
    --app-color:#2962FF;
    --primary-color:#3146a1;
    --secondary-color:#C52E59;
    --section-title-color:#333333;
    --divider-color:#cccccc;
    // --inactive-plan-color:#efefef;
    // --active-plan-color:#d3d3d3;
    --documents-color:#7A7A7A;
    --required-color:#ff4149;
    --white-color:#fff;
}