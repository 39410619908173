.mat-card {
  font-family: var(--font-family);
}

.mat-button {
  font-family: var(--font-family);
}

.primary-button.mat-button.mat-button-base {
  background-color: var(--secondary-color);
  color: white;
  width: 180px;
  height: 50px;
  font-size: 18px;
  border-radius: var(--primary-button-border-radius);
  font-weight: 400;

  &.main-button {
    background-color: var(--main-button-bg-color);
    color: var(--main-button-text-color);
    width: 200px;
    height: 65px;
    font-weight: 500;

    &:hover {
      background-image: var(--main-button-hover-bg-color);
      color: var(--main-button-hover-text-color);
    }

    &.how-button {
      height: 60px;
    }
  }

  &.widget-sale-button {
    background-image: var(--slider-widget-button-bg-image);
    background-color: var(--slider-widget-button-bg-color);
    color: var(--slider-widget-button-text-color);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 44%);
  }

  &.final-action-button {
    border-radius: 50px;
    background-color: white;
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-color);
    box-shadow: 0 1.4px 5px 0 rgb(0 0 0 / 50%);
  }

  &.pricing-plan-button {
    background-image: var(--pricing-card-subscribe-button-bg-image);

    &:hover {
      background-image: var(--pricing-card-subscribe-button-bg-image-hover);
    }
  }
}

.comments {
  .primary-button.mat-button.mat-button-base {
    width: 200px;
    height: 49px;
    font-size: 20px;
    border-radius: var(--comments-primary-button-border-radius);
    box-shadow: var(--comments-pricing-card-subscribe-button-shadow);

    &.pricing-plan-button {
      background-image: var(--comments-pricing-card-subscribe-button-bg-image);
    }
  }
}

.comments-primary-button.mat-button.mat-button-base {
  background: var(--comments-primary-color);
  color: white;
  width: 415px;
  height: 65px;
  font-size: 20px;
  border-radius: var(--comments-primary-button-border-radius);
  font-weight: 400;

  @media screen and(max-width: 520px) {
    width: 353px;
    height: 55px;
  }

  @media screen and(max-width: 420px) {
    width: 265px;
    height: 42px;
  }

  &.main-button {
    width: 415px;
    height: 65px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }
}

.whatsapp-button.mat-button.mat-button-base {
  background-color: #14a73f;
  width: 250px;
  height: 75px;
  border-radius: 100px;
  font-weight: 400;

  a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  @media screen and(max-width: 1200px) {
    width: 175px;
    height: 60px;

    @media screen and(max-width: 550px) {
      width: 160px;
      height: 55px;
    }
  }

  span {
    font-size: 25px;

    @media screen and(max-width: 1200px) {
      font-size: 20px;
    }

    @media screen and(max-width: 550px) {
      font-size: 16px;
    }
  }

  fa-icon {
    margin-right: 30px;
    font-size: 40px;

    @media screen and(max-width: 1200px) {
      margin-right: 15px;
      font-size: 30px;
    }

    @media screen and(max-width: 550px) {
      font-size: 28px;
    }
  }
}

//toolbar
.mat-toolbar {
  font-family: var(--font-family);

  .mat-toolbar-row {
    &:first-child {
      height: auto;
      padding: 0;
      margin: 0;
    }

    &:last-child {
      padding: 10px 0;
    }
  }
}

.mat-toolbar.mat-primary {
  position: fixed;
  top: 0;
  background-color: var(--header-toolbar-bg-color);

  &.transparent-header {
    &.header-overlay {
      background-color: rgba(0, 0, 0, 0.6);
      transition: background 0.3s, box-shadow 0.3s;
    }
  }
}

.mat-drawer.mat-sidenav.mat-drawer-opened {
  position: fixed;
  top: 119px;

  @media screen and(max-width: 600px) {
    top: 111px;
  }
}

.mat-drawer-container.mat-sidenav-container.mat-drawer-transition.mat-drawer-container-has-open {
  height: calc(100% - 64px);
  min-height: 0;
}

.mat-nav-list.mat-list-base {
  padding-top: 0;
  height: 100%;
}

.mat-drawer.mat-sidenav.ng-trigger.ng-trigger-transform.mat-drawer-over.ng-star-inserted {
  width: 100%;
  max-height: 330px;
  height: 100%;
}

//**Slider Widget

.mat-slider-horizontal {
  width: 230px;
}

//thumb

.mat-slider-thumb-container {
  :last-child::after {
    content: "";
    position: absolute;
    top: 63px;
    left: 6px;
    background-image: var(--slider-thumb-icon-src);
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused
  .mat-slider-thumb {
  border-color: white;
  background-color: white;
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb {
  background-color: white;
}

.mat-accent .mat-slider-thumb {
  background-color: white;
  width: 30px;
  height: 30px;
  bottom: -30px;
  right: -15px;
  transform: none;
  transition: none;
}

.mat-slider:not(.mat-slider-disabled).cdk-focused.mat-slider-horizontal.mat-accent.mat-slider-thumb-label-showing
  .mat-slider-thumb {
  transform: none;
}

//slider arrow
.mat-slider.mat-focus-indicator.mat-accent.mat-slider-horizontal.mat-slider-thumb-label-showing {
  > ::after {
    content: "";
    height: 0;
    width: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 10px solid white;
    position: absolute;
    right: -28px;
    top: 10px;
  }

  > ::before {
    content: "";
    height: 4px;
    width: 230px;
    background: white;
    position: absolute;
    top: 15px;
    left: -8px;
    z-index: 1;
    border-radius: 5px;
  }

  .mat-slider-wrapper {
    width: 200px;

    .mat-slider-track-wrapper {
      height: 32px;
      border-radius: 15px;
      width: 250px;
      background-image: var(--comments-slider-widget-track-wrapper);
      margin-left: -16px;

      .mat-slider-track-background {
        height: 100%;
        background: transparent;
      }

      .mat-slider-track-fill {
        height: 100%;
        background: transparent;
      }
    }
  }

  //thumb label
  .mat-slider-thumb-label {
    background-color: var(--slider-widget-thumb-label-color);
    border-radius: 20px;
    width: 70px;
    height: 32px;
    right: -49px;
    top: -63px;
    transform: none;

    ::after {
      content: "";
      height: 0;
      width: 0;
      border-top: 7px solid var(--slider-widget-thumb-label-color);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      position: absolute;
      left: 8px;
      top: 25px;
    }

    .mat-slider-thumb-label-text {
      font-family: var(--font-family);
      opacity: 1;
      font-size: 20px;
      transform: rotate(0deg);
    }
  }
}

// Comments slider widget
.comments-widget {
  .mat-slider-horizontal {
    width: 230px;
  }

  //thumb

  .mat-slider-thumb-container {
    :last-child::after {
      content: "";
      position: absolute;
      top: 39px;
      left: 6px;
      background-image: var(--comments-slider-thumb-icon-src);
      background-size: 30px 30px;
      width: 30px;
      height: 30px;
      border-radius: 71px;
    }
  }

  .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused
    .mat-slider-thumb {
    border-color: transparent;
    background-color: transparent;
  }

  .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb {
    background-color: transparent;
  }

  .mat-accent .mat-slider-thumb {
    background-color: transparent;
    width: 30px;
    height: 30px;
    bottom: -21px;
    right: -23px;
    transform: none;
    transition: none;
  }

  .mat-slider:not(.mat-slider-disabled).cdk-focused.mat-slider-horizontal.mat-accent.mat-slider-thumb-label-showing
    .mat-slider-thumb {
    transform: none;
  }

  //slider arrow
  .mat-slider.mat-focus-indicator.mat-accent.mat-slider-horizontal.mat-slider-thumb-label-showing {
    > ::after {
      content: none;
      height: 0;
      width: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 10px solid white;
      position: absolute;
      right: -28px;
      top: 10px;
    }

    > ::before {
      content: none;
      height: 4px;
      width: 230px;
      background: white;
      position: absolute;
      top: 15px;
      left: -8px;
      z-index: 1;
      border-radius: 5px;
    }

    .mat-slider-wrapper {
      // width: 200px;
      width: 215px;

      .mat-slider-track-wrapper {
        height: 15px;
        border-radius: 15px;
        width: 250px;
        background-image: var(--slider-widget-track-wrapper);
        margin-left: -16px;

        .mat-slider-track-background {
          height: 100%;
          background: transparent;
        }

        .mat-slider-track-fill {
          height: 100%;
          background: transparent;
        }
      }
    }

    //thumb label
    .mat-slider-thumb-label {
      background-color: var(--comments-slider-widget-thumb-label-color);
      border-radius: 5px;
      width: 40px;
      height: 25px;
      right: -21px;
      top: -45px;
      transform: none;

      ::after {
        content: "";
        height: 0;
        width: 0;
        border-top: 10px solid var(--comments-slider-widget-thumb-label-color);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        left: 5px;
        top: 20px;
      }

      .mat-slider-thumb-label-text {
        font-family: var(--font-family);
        font-weight: var(--comments-slider-text-font-weight);
        opacity: 1;
        font-size: 20px;
        transform: rotate(0deg);
      }
    }
  }
}

//accordion

.mat-expansion-panel-header {
  font-family: var(--font-family);
}

.mat-expansion-panel-content {
  font-family: var(--font-family);
}

.mat-content .mat-expansion-panel-header-title {
  color: var(--main-faq-section-question-color);
  margin-right: 0;
}

.mat-expansion-panel-body {
  background-color: var(--main-faq-section-answer-bg-color);
}

.first .mat-accordion .mat-expansion-panel {
  border-top-left-radius: var(--main-faq-section-border-radius-top) !important;
  border-top-right-radius: var(--main-faq-section-border-radius-top) !important;
}

.last .mat-accordion .mat-expansion-panel {
  border-bottom-left-radius: var(
    --main-faq-section-border-radius-bottom
  ) !important;
  border-bottom-right-radius: var(
    --main-faq-section-border-radius-bottom
  ) !important;
}

.mat-expansion-panel-header.mat-focus-indicator.question.mat-expansion-toggle-indicator-after.ng-star-inserted.mat-expanded {
  background-color: var(--main-faq-section-question-bg-color-active) !important;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

input,
textarea {
  font-family: var(--font-family);
}

//dialog
.cdk-overlay-pane {
  max-width: 90vw !important;
}

.mat-dialog-container.ng-trigger.ng-trigger-dialogContainer.ng-star-inserted {
  border-radius: 10px;
  width: 600px;
  min-width: 340px;
  padding: 0;
  overflow: visible;

  @media screen and(max-width: 450px) {
    width: 100%;
  }

  .close-modal {
    background-color: var(--modal-close-button-bg-color);
    color: var(--modal-close-button-text-color);
    box-shadow: 0 1.4px 5px 0 rgb(0 0 0 / 50%);
    font-size: 21px;
    padding: 0;
    line-height: 30px;
    border-radius: 50%;
    min-width: 30px;
    width: 30px;
    height: 30px;
  }

  .back-button-modal {
    background-color: var(--modal-back-button-bg-color);
    color: var(--modal-back-button-text-color);
    box-shadow: 0 1.4px 5px 0 rgb(0 0 0 / 50%);
    font-size: 21px;
    padding: 0;
    line-height: 30px;
    border-radius: 50%;
    min-width: 30px;
    width: 30px;
    height: 30px;
  }

  .mat-dialog-title {
    font-family: var(--font-family);
    text-align: center;
    color: white;
    @media screen and(max-width: 450px) {
      margin: 5px 0 0 0;
    }
  }

  h1 {
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: 700;
  }

  h2 {
    font-size: 16px;
    margin: 0;
  }

  .mat-form-field {
    font-family: var(--font-family);

    .mat-select-arrow {
      border-top: 5px solid white;
    }
  }

  .mat-form-field-label .input-label {
    font-family: var(--font-family);
    color: white;
    font-size: 18px;
  }

  .mat-form-field-underline {
    background-color: white;
  }

  .mat-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .mat-form-field.mat-warn .mat-input-element,
  .mat-form-field-invalid .mat-input-element {
    caret-color: var(--form-error-color);
  }
  .mat-error {
    color: var(--form-error-color);
    font-weight: var(--form-error-font-weight);
  }

  .instagram-content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    input.search-bar {
      text-align: center;
      width: 300px;
      border-radius: 25px;
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #fff;
      box-shadow: 0 1.4px 5px 0 rgb(0 0 0 / 50%);
      padding: 10px 0;
      outline: none;
      -webkit-appearance: none;
      @media screen and(max-width: 750px) {
        width: 250px;
      }

      @media screen and(max-width: 550px) {
        width: 190px;
      }
    }
  }

  .mat-dialog-actions {
    border-top: 1px solid var(--divider-color);
    height: 70px;
    width: 100%;
    padding: 0;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 10px;

    .mat-button.mat-button-base {
      background-color: var(--modal-bottom-close-button-bg-color);
      color: var(--modal-bottom-close-button-text-color);
      box-shadow: 0 1.4px 5px 0 rgb(0 0 0 / 50%);
      border: 1px solid #efefef;
      font-weight: 600;
      min-width: 110px;
      height: 44px;
      border-radius: 25px;
      font-size: 16px;

      &.payment-button {
        border-radius: 50px;
        background-color: white;
        font-size: 18px;
        font-weight: 700;
        color: var(--secondary-color);
        box-shadow: 0 1.4px 5px 0 rgb(0 0 0 / 50%);
        min-width: 280px;

        &.disabled {
          background-color: gray;
          color: white;
        }
      }
    }
  }
}

//checkbox
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--checkbox-bg-color);
}

//general
.mat-list-base.benefits-list {
  padding-top: 0;

  .mat-list-item {
    height: 35px;

    .mat-list-item-content {
      font-family: var(--font-family);

      @media screen and(max-width: 480px) {
        //   padding: 0 !important;
      }
    }
  }
}

//select
mat-option.mat-option .mat-option-text {
  font-size: 18px;
  font-family: var(--font-family);
}

mat-option.mat-option.ng-star-inserted {
  font-size: 18px;
  font-family: var(--font-family);
  color: var(--dropdown-not-selected-package-color);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--dropdown-selected-package-color);
}

.mat-optgroup.ng-star-inserted {
  font-size: 18px;
  font-family: var(--font-family);
}

.mat-select-value {
  font-size: 18px;
  font-family: var(--font-family);
  color: white;
}

/*progress bar*/
.mat-progress-bar.mat-primary {
  height: 6px;
  border-radius: 5px;

  .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element {
    &::after {
      background-color: var(--progress-bar-color);
    }
  }
}

/*spinner*/
.mat-spinner.mat-progress-spinner.signup-spinner.mat-primary.mat-progress-spinner-indeterminate-animation {
  circle {
    stroke: var(--signup-spinner-color);
  }
}

.swiper {
  width: 100%;
  height: 600px;

  .swiper-pagination {
    bottom: -4px !important;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
