.vibrate-1 {
	animation: vibrate-1 8s ease-in-out infinite both;
}
@keyframes vibrate-1 {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-4px, 4px);
  }
  40% {
    transform: translate(-4px, -4px);
  }
  60% {
    transform: translate(20px, 8px);
  }
  80% {
    transform: translate(8px, -8px);
  }
  100% {
    transform: translate(0);
  }
}

/**
 * ----------------------------------------
 * animation vibrate-2
 * ----------------------------------------
 */
.vibrate-2 {
	animation: vibrate-2 12s ease-in-out infinite both;
}
@keyframes vibrate-2 {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-7px, 7px);
  }
  40% {
    transform: translate(-7px, -7px);
  }
  60% {
    transform: translate(40px, 10px);
  }
  80% {
    transform: translate(7px, -7px);
  }
  100% {
    transform: translate(0);
  }
}

/**
 * ----------------------------------------
 * animation vibrate-3
 * ----------------------------------------
 */
.vibrate-3 {
	animation: vibrate-3 6s ease-in-out infinite both;
}
@keyframes vibrate-3 {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-8px, 4px);
  }
  40% {
    transform: translate(-4px, -10px);
  }
  60% {
    transform: translate(6px, 6px);
  }
  80% {
    transform: translate(6px, -6px);
  }
  100% {
    transform: translate(0);
  }
}
