/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/base";
@import "./assets/styles/common";
@import "./assets/styles/colors";
@import "./assets/styles/fonts";
@import "./assets/styles/overrides";
@import "./assets/styles/animations";
@import "~animate.css/animate.min";
@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";

html,
body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: var(--font-family) !important;
}
